<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import { required } from "vuelidate/lib/validators";

export default {
  locales: {
    pt: {
    },
    es: {
    }
  },
  components: {
    Layout,
  },
  data() {
    return {
      statData: null,

      loading: {
        confirm: false,
        fipe: false,
      },

      vehicles: null,

      types: null,
      brands: null,
      years: null,
      models: null,
      states: [
        { "code": "AC", "name": "Acre", },
        { "code": "AL", "name": "Alagoas", },
        { "code": "AP", "name": "Amapá", },
        { "code": "AM", "name": "Amazonas", },
        { "code": "BA", "name": "Bahia", },
        { "code": "CE", "name": "Ceará", },
        { "code": "DF", "name": "Distrito Federal", },
        { "code": "ES", "name": "Espírito Santo", },
        { "code": "GO", "name": "Goiás", },
        { "code": "MA", "name": "Maranhão", },
        { "code": "MT", "name": "Mato Grosso", },
        { "code": "MS", "name": "Mato Grosso do Sul", },
        { "code": "MG", "name": "Minas Gerais", },
        { "code": "PA", "name": "Pará", },
        { "code": "PB", "name": "Paraíba", },
        { "code": "PR", "name": "Paraná", },
        { "code": "PE", "name": "Pernambuco", },
        { "code": "PI", "name": "Piauí", },
        { "code": "RJ", "name": "Rio de Janeiro", },
        { "code": "RN", "name": "Rio Grande do Norte", },
        { "code": "RS", "name": "Rio Grande do Sul", },
        { "code": "RO", "name": "Rondônia", },
        { "code": "RR", "name": "Roraima", },
        { "code": "SC", "name": "Santa Catarina", },
        { "code": "SP", "name": "São Paulo", },
        { "code": "SE", "name": "Sergipe", },
        { "code": "TO", "name": "Tocantins", },
      ],
      cities: null,

      create: {
        step: 1,
        modal: false,

        vehicle: {
          type: '',
          brand: '',
          year: '',
          model: '',
          state: '',
          city: '',
          work: '',
        },

        confirm: null,

        fipe: null,
      },
    };
  },
  validations: {
    create: {
      type: { required },
      brand: { required },
      model: { required },
      year: { required },
    },
  },
  methods: {
    getVehicles() {
      this.vehicles = null;

      api
        .get('vehicles')
        .then((response) => {
          if (response.data.status == 'success') {
            this.vehicles = response.data.list;

            if (this.vehicles && this.vehicles.length == 0) {
              this.showCreate()
            } else if (this.vehicles && this.vehicles[0].status == 'pending' && this.vehicles.length == 1) {
              this.$router.push('/vehicles/' + this.vehicles[0].id)
            }
          } else {
            this.showCreate()
          }
        })
    },
    showCreate() {
      this.create.step = 1;
      this.create.modal = false;
      this.create.modal = true;
    },
    getTypes() {
      this.types = null;
      this.brands = null;
      this.years = null;
      this.models = null;

      api
        .post('vehicles/types', {})
        .then((response) => {
          this.types = response.data.list
        })
    },
    getBrands() {
      this.brands = null;
      this.years = null;
      this.models = null;

      if (this.create.vehicle.type) {
        this.create.vehicle.brand = '';
        this.create.vehicle.year = '';
        this.create.vehicle.model = '';

        api
          .post('vehicles/brands', { type: this.create.vehicle.type })
          .then((response) => {
            this.brands = response.data.list
          })
      }
    },
    getYears() {
      this.years = null;
      this.models = null;

      if (this.create.vehicle.type && this.create.vehicle.brand) {
        this.create.vehicle.year = '';
        this.create.vehicle.model = '';

        api
          .post('vehicles/years', {
            type: this.create.vehicle.type,
            brand: this.create.vehicle.brand,
          })
          .then((response) => {
            this.years = response.data.list
          })
      }
    },
    getModels() {
      if (this.create.vehicle.type && this.create.vehicle.brand && this.create.vehicle.year) {
        this.create.vehicle.model = '';

        api
          .post('vehicles/models', {
            type: this.create.vehicle.type,
            brand: this.create.vehicle.brand,
            year: this.create.vehicle.year,
          })
          .then((response) => {
            this.models = response.data.list
          })
      }
    },
    getCities() {
      this.cities = null;

      if (this.create.vehicle.state) {
        api
          .post('vehicles/cities', {
            state: this.create.vehicle.state
          })
          .then((response) => {
            this.cities = response.data.list
          })
      }
    },
    getConfirm() {
      if (this.create.vehicle.type && this.create.vehicle.brand && this.create.vehicle.year && this.create.vehicle.model) {
        this.loading.confirm = true

        api
          .post('vehicles/confirm', {
            type: this.create.vehicle.type,
            brand: this.create.vehicle.brand,
            year: this.create.vehicle.year,
            model: this.create.vehicle.model,
            state: this.create.vehicle.state,
            city: this.create.vehicle.city,
            work: this.create.vehicle.work,
          })
          .then((response) => {
            if (response.data.status == 'success') {
              this.create.step = 3
              this.create.confirm = response.data.vehicle
            } else {
              this.$toast.error(response.data.message)
            }
          })
          .finally(() => {
            this.loading.confirm = false
          })
      }
    },
    getFipe() {
      if (this.create.vehicle.type && this.create.vehicle.brand && this.create.vehicle.year && this.create.vehicle.model) {
        this.loading.fipe = true

        api
          .post('vehicles/fipe', {
            type: this.create.vehicle.type,
            brand: this.create.vehicle.brand,
            year: this.create.vehicle.year,
            model: this.create.vehicle.model,
          })
          .then((response) => {
            if (response.data.status == 'success') {
              this.create.step = 4
              this.create.fipe = response.data.fipe
            } else {
              this.$toast.error(response.data.message)
            }
          })
          .finally(() => {
            this.loading.fipe = false
          })
      }
    },
    checkoutPlan() {
      if (
        this.create.vehicle.type &&
        this.create.vehicle.brand &&
        this.create.vehicle.model &&
        this.create.vehicle.year &&
        this.create.vehicle.state &&
        this.create.vehicle.city &&
        this.create.vehicle.work
      ) {
        this.loading.checkout = true

        api
          .post('vehicles', {
            type: this.create.vehicle.type,
            brand: this.create.vehicle.brand,
            model: this.create.vehicle.model,
            year: this.create.vehicle.year,
            state: this.create.vehicle.state,
            city: this.create.vehicle.city,
            work: this.create.vehicle.work,
          })
          .then((response) => {
            if (response.data.status == 'success') {
              this.loading.checkout = false
              this.create.modal = false

              this.$router.push('/vehicles/' + response.data.vehicle.id)
            } else {
              this.$toast.error(response.data.message)
            }
          })
          .finally(() => {
            this.loading.checkout = false
          })
      }
    },
    showPayment() {
      this.payment.modal = false
      this.payment.modal = true
    }
  },
  mounted() {
    this.getVehicles()
    this.getTypes()
  },
  watch: {
    "create.vehicle.type": function () {
      this.getBrands();
    },
    "create.vehicle.brand": function () {
      this.getYears();
    },
    "create.vehicle.year": function () {
      this.getModels();
    },
    "create.vehicle.state": function () {
      this.getCities();
    },
  },
};
</script>

<template>
  <Layout>
    <div class="page-title-box d-md-flex justify-content-between mb-3">
      <div>
        <h3>{{ t('Meus Veículos') }}</h3>
        <p></p>
      </div>
    </div>

    <div v-if="vehicles && vehicles.length > 0" id="dropdown" class="d-flex mb-4">
      <b-dropdown class="bg-white" variant="outline-light">
        <template #button-content>
          Ações
          <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="m12 14.168-5.582-5.61L5 9.967 12 17l7-7-1.415-1.414L12 14.168Z" fill="currentColor"></path>
          </svg>
        </template>
        <b-dropdown-item v-on:click="showCreate()">Novo Veículo</b-dropdown-item>
      </b-dropdown>
    </div>

    <div class="card" v-if="!vehicles">
      <div class="card-body min-vh-50 d-flex justify-content-center align-items-center">
        <b-spinner variant="default" role="status"></b-spinner>
      </div>
    </div>

    <div class="card" v-else-if="vehicles && vehicles.length == 0">
      <div class="card-body min-vh-50 d-flex justify-content-center align-items-center">
        <div class="text-center">
          <i class="far fa-sad-cry font-size-24"></i><br>
          nenhum veículo encontrado
        </div>
      </div>
    </div>

    <div v-else>
      <div class="row">
        <div class="col-md-6 col-lg-4" v-for="(row, id) in vehicles" :key="id">
          <router-link tag="a" :to="'/vehicles/' + row.id" class="text-dark">
            <div class="card">
              <div class="card-body">
                <div class="d-flex align-items-center mb-2">
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
                      <path
                        d="M3 19.723V21a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1v-1h12v1a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1v-1.277A1.99 1.99 0 0 0 22 18v-3c0-.831-.507-1.542-1.228-1.845l-1.368-4.104A2.995 2.995 0 0 0 16.559 7H7.441a2.995 2.995 0 0 0-2.845 2.051l-1.368 4.104A2.001 2.001 0 0 0 2 15v3c0 .738.404 1.376 1 1.723zM5.5 18a1.5 1.5 0 1 1 .001-3.001A1.5 1.5 0 0 1 5.5 18zm13 0a1.5 1.5 0 1 1 .001-3.001A1.5 1.5 0 0 1 18.5 18zM7.441 9h9.117a1 1 0 0 1 .949.684L18.613 13H5.387l1.105-3.316c.137-.409.519-.684.949-.684z">
                      </path>
                      <path d="M22 7.388V5.279l-9.684-3.228a.996.996 0 0 0-.658.009L2 5.572V7.7l10.015-3.642L22 7.388z"></path>
                    </svg>
                  </div>
                  <div class="ml-2 flex-fill">
                    <h5 class="mb-0">{{ row.brand }} {{ row.year }}</h5>
                  </div>
                  <div>
                    <span class="bg-warning rounded px-1 font-size-12 text-white text-uppercase" v-if="row.status == 'pending'">Pendente</span>
                    <span class="bg-success rounded px-1 font-size-12 text-white text-uppercase" v-else-if="row.status == 'approved'">Aprovado</span>
                    <span class="bg-success rounded px-1 font-size-12 text-white text-uppercase" v-else-if="row.status == 'active'">Ativo</span>
                    <span class="bg-danger rounded px-1 font-size-12 text-white text-uppercase" v-else-if="row.status == 'inactive'">Inativo</span>
                    <span class="bg-danger rounded px-1 font-size-12 text-white text-uppercase" v-else-if="row.status == 'canceled'">Cancelado</span>
                  </div>
                </div>
                <div class="mb-2">
                  {{ row.model }}<br>
                  {{ row.city }}/{{ row.state }}
                </div>
                <div>
                  <h5 class="mb-0">{{ row.plan | currency }}</h5>
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>



    <b-modal v-model="create.modal" modal-class="modal-right" hide-header hide-footer>
      <h4>Proteja seu veículo!</h4>
      <p></p>

      <b-progress :value="create.step" max="4" variant="default" height="15px"></b-progress>

      <template v-if="create.step == 1">
        <div class="my-5">
          <b-form-group class="vehicle-radios" label="Escolha o tipo veículo">
            <b-form-radio v-model="create.vehicle.type" value="cars">Carro</b-form-radio>
            <b-form-radio v-model="create.vehicle.type" value="motorcycle">Moto</b-form-radio>
            <b-form-radio v-model="create.vehicle.type" value="trucks">Caminhão</b-form-radio>
          </b-form-group>
          <div class="row">
            <div class="col-6">
              <b-form-group label="Marca">
                <b-form-select v-model="create.vehicle.brand" required>
                  <option v-if="create.vehicle.type && !brands" value="">Carregando...</option>
                  <option v-else-if="brands" value="">– Selecione</option>
                  <option v-else value="">–</option>
                  <option v-for="(option, index) in brands" :key="index" :value="option.code">
                    {{ option.name }}
                  </option>
                </b-form-select>
              </b-form-group>
            </div>
            <div class="col-6">
              <b-form-group label="Ano">
                <b-form-select v-model="create.vehicle.year" required>
                  <option v-if="create.vehicle.brand && !years" value="">Carregando...</option>
                  <option v-else-if="years" value="">– Selecione</option>
                  <option v-else value="">–</option>
                  <option v-for="(option, index) in years" :key="index" :value="option.code">
                    {{ option.name }}
                  </option>
                </b-form-select>
              </b-form-group>
            </div>
          </div>
          <b-form-group label="Modelo">
            <b-form-select v-model="create.vehicle.model" required>
              <option v-if="create.vehicle.year && !models" value="">Carregando...</option>
              <option v-else-if="models" value="">– Selecione</option>
              <option v-else value="">–</option>
              <option v-for="(option, index) in models" :key="index" :value="option.code">
                {{ option.name }}
              </option>
            </b-form-select>
          </b-form-group>
        </div>
        <div class="d-flex justify-content-between align-items-center">
          <button class="btn btn-default btn-lg btn-block" :disabled="create.vehicle.type == '' || create.vehicle.brand == '' || create.vehicle.year == '' || create.vehicle.model == ''"
            v-on:click="create.step = 2">Próximo passo</button>
        </div>
      </template>
      <template v-else-if="create.step == 2">
        <div class="my-5">
          <b-form-group label="Moro no estado">
            <b-form-select v-model="create.vehicle.state" required>
              <option value="">–</option>
              <option v-for="(option, index) in states" :key="index" :value="option.code">
                {{ option.name }}
              </option>
            </b-form-select>
          </b-form-group>
          <b-form-group label="Na cidade">
            <b-form-select v-model="create.vehicle.city" required>
              <option v-if="create.vehicle.state && !cities" value="">Carregando...</option>
              <option v-else-if="cities" value="">– Selecione</option>
              <option v-else value="">–</option>
              <option v-for="(option, index) in cities" :key="index" :value="option.code">
                {{ option.name }}
              </option>
            </b-form-select>
          </b-form-group>
          <b-form-group class="vehicle-radios" label="Você usa o seu veículo em Táxi ou Aplicativo?">
            <b-form-radio v-model="create.vehicle.work" value="yes">Sim</b-form-radio>
            <b-form-radio v-model="create.vehicle.work" value="no">Não</b-form-radio>
          </b-form-group>
        </div>
        <div class="d-flex justify-content-between align-items-center">
          <button class="btn btn-light btn-lg" v-on:click="create.step = 1">Voltar</button>
          <button class="btn btn-default btn-lg" :disabled="create.vehicle.state == '' || create.vehicle.city == '' || create.vehicle.work == '' || loading.confirm" v-on:click="getConfirm();">
            Próximo passo
            <b-spinner v-if="loading.confirm" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
          </button>
        </div>
      </template>
      <template v-else-if="create.step == 3">
        <div class="my-5">
          <h5 class="mb-4">Confira o seu veículo</h5>
          <div class="card">
            <div class="card-body" v-if="create.confirm">
              <div class="d-flex justify-content-between">
                <div>Marca</div>
                <div>{{ create.confirm.brand }}</div>
              </div>
              <div class="d-flex justify-content-between">
                <div>Ano</div>
                <div>{{ create.confirm.year }}</div>
              </div>
              <div class="d-flex justify-content-between">
                <div>Modelo</div>
                <div>{{ create.confirm.model }}</div>
              </div>
              <div class="d-flex justify-content-between">
                <div>Estado</div>
                <div>{{ create.confirm.state }}</div>
              </div>
              <div class="d-flex justify-content-between">
                <div>Cidade</div>
                <div>{{ create.confirm.city }}</div>
              </div>
              <div class="d-flex justify-content-between">
                <div>Táxi ou Aplicativo?</div>
                <div v-if="create.vehicle.work == 'yes'">Sim</div>
                <div v-else>Não</div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-between align-items-center">
          <button class="btn btn-light btn-lg" v-on:click="create.step = 2">Voltar</button>
          <button class="btn btn-default btn-lg" :disabled="loading.fipe" v-on:click="getFipe()">
            Próximo passo
            <b-spinner v-if="loading.fipe" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
          </button>
        </div>
      </template>
      <template v-else-if="create.step == 4">
        <div class="my-5">
          <h5 class="mb-4">Confira o valor da sua adesão</h5>
          <div class="card mb-1">
            <div class="card-body">
              <div class="mt-1"><span class="bg-soft-warning rounded py-1 px-2 font-size-12 text-uppercase">Veículo</span></div>
              <div class="mt-2 px-1">
                {{ create.confirm.brand }}<br>
                {{ create.confirm.model }}<br>
                {{ create.confirm.year }}
              </div>
            </div>
          </div>
          <div v-if="create.fipe && create.fipe.plan" class="card">
            <div class="card-body">
              <div class="mt-1"><span class="bg-soft-warning rounded py-1 px-2 font-size-12 text-uppercase">Valor da Adesão</span></div>
              <div class="mt-2 px-1">{{ create.fipe.plan | currency }}</div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-between align-items-center">
          <button class="btn btn-light btn-lg" v-on:click="create.step = 3">Voltar</button>
          <button class="btn btn-default btn-lg" :disabled="loading.checkout" v-on:click="checkoutPlan()">
            Finalizar
            <b-spinner v-if="loading.checkout" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
          </button>
        </div>
      </template>

      <div class="mt-5">
        <a class="btn btn-outline-link" v-on:click="create.modal = false">Fechar</a>
      </div>
    </b-modal>
  </Layout>
</template>

<style>
.vehicle-radios>div {
  display: flex;
  flex-wrap: wrap;
  gap: 10px
}

.vehicle-radios>div .custom-control {
  padding-left: 0;
}

.vehicle-radios>div .custom-radio .custom-control-label {
  border: solid 1px #D1D1E3;
  border-radius: 6px;
  padding: 13px 13px 13px 43px;
  font-size: 14px;
}

.vehicle-radios>div .custom-radio .custom-control-label::before {
  top: 12px;
  left: 9px;
  width: 1.5rem;
  height: 1.5rem;
}

.vehicle-radios>div .custom-radio .custom-control-label::after {
  top: 12px;
  left: 9px;
  width: 1.5rem;
  height: 1.5rem;
}
</style>
